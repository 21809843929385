<template>
  <div>
    <span>Tipo de Usuário</span>
    <v-radio-group
      dense
      hide-details
      :readonly="sending"
      row
      :value="value"
      @change="$emit('change', $event)"
    >
      <div>
        <span v-if="options.ADMINISTRADOR.show || options.CONSULTOR.show">Galapos</span>
        <v-radio
          v-if="options.ADMINISTRADOR.show"
          :label="options.ADMINISTRADOR.label"
          :value="options.ADMINISTRADOR.value"
        ></v-radio>
        <v-radio
          v-if="options.CONSULTOR.show"
          :label="options.CONSULTOR.label"
          :value="options.CONSULTOR.value"
        ></v-radio>
      </div>

      <div>
        <span v-if="options.CLIENTE_ADMINISTRADOR.show || options.CLIENTE_CONSULTOR.show">Cliente SaaS</span>
        <v-radio
          v-if="options.CLIENTE_ADMINISTRADOR.show"
          :label="options.CLIENTE_ADMINISTRADOR.label"
          :value="options.CLIENTE_ADMINISTRADOR.value"
        ></v-radio>
        <v-radio
          v-if="options.CLIENTE_CONSULTOR.show"
          :label="options.CLIENTE_CONSULTOR.label"
          :value="options.CLIENTE_CONSULTOR.value"
        ></v-radio>
      </div>

      <div>
        <span v-if="options.GERENCIAL_CLIENTE.show || options.COLABORADOR.show">Cliente</span>
        <v-radio
          v-if="options.GERENCIAL_CLIENTE.show"
          :label="options.GERENCIAL_CLIENTE.label"
          :value="options.GERENCIAL_CLIENTE.value"
        ></v-radio>
        <v-radio
          v-if="options.COLABORADOR.show"
          :label="options.COLABORADOR.label"
          :value="options.COLABORADOR.value"
        ></v-radio>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UserTypeEnum } from '@/core/enums/user-types';
import { isLowerInOrder } from '@/helpers/getHierarchicalOrder';

export default {
  computed: {
    ...mapGetters(['user']),
    options: function () {
      const loggedUserType = this.user.tipo_usuario;

      return {
        ADMINISTRADOR: {
          value: UserTypeEnum.ADMINISTRADOR,
          label: "Administrador",
          show: isLowerInOrder(UserTypeEnum.ADMINISTRADOR, loggedUserType),
        },
        CLIENTE_ADMINISTRADOR: {
          value: UserTypeEnum.CLIENTE_ADMINISTRADOR,
          label: "Cliente Administrador",
          show: isLowerInOrder(UserTypeEnum.CLIENTE_ADMINISTRADOR, loggedUserType),
        },
        CLIENTE_CONSULTOR: {
          value: UserTypeEnum.CLIENTE_CONSULTOR,
          label: "Cliente Consultor",
          show: isLowerInOrder(UserTypeEnum.CLIENTE_CONSULTOR, loggedUserType),
        },
        COLABORADOR: {
          value: UserTypeEnum.COLABORADOR,
          label: "Operacional",
          show: isLowerInOrder(UserTypeEnum.COLABORADOR, loggedUserType),
        },
        CONSULTOR: {
          value: UserTypeEnum.CONSULTOR,
          label: "Consultor",
          show: loggedUserType !== UserTypeEnum.CONSULTOR && isLowerInOrder(UserTypeEnum.CONSULTOR, loggedUserType),
        },
        GERENCIAL_CLIENTE: {
          value: UserTypeEnum.GERENCIAL_CLIENTE,
          label: "Gerencial",
          show: isLowerInOrder(UserTypeEnum.GERENCIAL_CLIENTE, loggedUserType),
        },
      }
    },
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    sending: { type: Boolean, default: false },
    value: { type: [String, Number], default: null },
  },
}
</script>

<style lang="scss" scoped>
.v-input--radio-group__input > div {
  padding: 0 .5rem;
  margin: .5rem 0;

  span {
    color: rgba(0,0,0,.6);
    font-weight: 500;
    padding-left: .75rem;
  }
}

.v-input--radio-group {
  border: thin solid rgba(0, 0, 0, .12);
  border-radius: 5px;
  margin-top: .5rem;
}

.v-radio {
  padding: .5rem;
}
</style>
