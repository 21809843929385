import { render, staticRenderFns } from "./tipo-usuario.form.vue?vue&type=template&id=238cce0b&scoped=true&"
import script from "./tipo-usuario.form.vue?vue&type=script&lang=js&"
export * from "./tipo-usuario.form.vue?vue&type=script&lang=js&"
import style0 from "./tipo-usuario.form.vue?vue&type=style&index=0&id=238cce0b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "238cce0b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VRadio,VRadioGroup})
