import { hierarchicalOrder } from '@/core/enums/user-types';

export const isHigherInOrder = (userTypeA, userTypeB) => {
  if (!hierarchicalOrder.includes(userTypeA)) {
    throw new Error(`${userTypeA} não é um tipo de usuário do sistema`);
  }

  if (!hierarchicalOrder.includes(userTypeB)) {
    throw new Error(`${userTypeB} não é um tipo de usuário do sistema`);
  }

  const positionA = hierarchicalOrder.indexOf(userTypeA);
  const positionB = hierarchicalOrder.indexOf(userTypeB);

  return userTypeA !== userTypeB && positionA < positionB;
};

export const isLowerInOrder = (userTypeA, userTypeB) => !isHigherInOrder(userTypeA, userTypeB);
